<template>
    <div class="SearchFolder">
        <span class="spanClass">
            <i class="iconfont icon-search"></i>
            <form v-on:submit.prevent action="" class="formBox">
                <input placeholder="搜索" type="search" @focus="flg=true" @search="toSearch" v-model="searchValue" @keyup.enter="toSearch()" />
            </form>
        </span>
        <span v-if="flg" class="nextClass" @click="cancelSearch()">取消</span>
        <span v-else class="nextClass" @click="$emit('optionsOpen')">下属</span>
    </div>
</template>
<script>
export default {
    name: 'FolderSelection',
    // props: ['boxId'],
    data() {
        return {
            flg: false,
            searchValue: ''
        }
    },
    computed: {

    },
    created() {
    },
    mounted() {
    },
    methods: {
        toSearch() {
            this.$emit('searchClick', true, this.searchValue)
        },
        cancelSearch() {
            this.flg = false
            this.searchValue = ''
            this.$emit('searchClick', false, '')
        }

    },
    watch: {
        $route(to, from) {
            // this.pageInit();
            if (this.$route.path == '/mail/home') {
                this.searchValue = ''
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
