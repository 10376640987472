<template>
    <div>
        <loading-new :loading="isLoading" :show1="true" v-if="isLoading"></loading-new>
        <div class="Home" v-if="!isLoading">
            <!-- <foot-nav></foot-nav> -->
            <div :class="{'navView': true, 'appNavView': getIsApp()}">
                <search-folder @optionsOpen="optionsOpen" @searchClick="searchClick"></search-folder>
                <div v-show="!searchMailIs" class="treeWrap">
                    <div class="treeItem teshu">
                        <div class="title" @click="UndistributedMail()">
                            <i class="iconfont iconThis icon-mail-not"></i>
                            <span>
                                未分发邮件
                                <!-- {{$t('mxpcweb.mail.1528941834774')}} -->

                            </span>
                            <i data-v-e6f81cda="" class="iconfont corlor icon-page-right"></i>
                        </div>
                    </div>
                    <div class="treeItem" @click="getTopMailList">
                        <div class="title">
                            <i class="iconfont iconThis icon-star"></i>
                            <span>
                                置顶邮件
                                <!-- {{$t('mxpcweb.mail.1528941835031')}} -->
                            </span>
                            <i class="iconfont corlor  icon-page-right"></i>
                            <span style="float:right;margin-right: 5px;color:rgba(144,147,153,1);"></span>
                        </div>
                    </div>
                    <div class="treeItem">
                        <div class="title" @click="fileAction()">
                            <i class="iconfont iconThis icon-mail"></i>
                            <span>
                                {{AccountNmber==''?myAccount:AccountNmber}}
                            </span>
                            <i class="iconfont corlor icon-page-down"></i>
                            <span v-if="showState" class="editClass" @click.stop="showState = false">编辑</span>
                        </div>
                        <template>
                            <van-radio-group v-model="radio">
                                <ul class="T_down" v-for="(item,index) in myMailTree" :key="index">
                                    <tree-menu :treeData="item" :RowIndex="index" :myMailTree="myMailTree" :showState="showState"></tree-menu>
                                </ul>
                            </van-radio-group>
                        </template>

                    </div>
                    <div class="treeItem">
                        <div class="title">
                            <i class="iconfont iconThis icon-heart"></i>
                            <span>
                                关注客户
                            </span>
                        </div>
                        <template>
                            <ul class="T_down" v-for="(item,index) in customerArray" :key="index" @click="CustomerClick(item.custId,item.custName)">
                                <span class="label">
                                    <span class="labelColor">{{item.custName}}</span>
                                </span>
                                <!-- <span class="unReadClass">
                                {{item.custId}}
                                <i class="iconfont  icon-page-right"></i>
                            </span> -->
                            </ul>
                        </template>
                    </div>
                </div>
            </div>
            <div class="footClass" v-if="showState==false">
                <li @click="actionFolder()">
                    <i class="iconfont icon-folder-add"></i>
                </li>
                <li @click="editFolder()">
                    <i class="iconfont icon-edit-light"></i>
                </li>
                <li @click="Move()">
                    <i class="iconfont icon-move-up"></i>
                </li>
                <li @click="Down()">
                    <i class="iconfont icon-move-down"></i>
                </li>
                <li @click="DleteFolder()">
                    <i class="iconfont icon-del-light"></i>
                </li>
                <li @click="colseShow()">
                    <i class="iconfont icon-close"></i>
                </li>
            </div>
            <personnel-selection ref="PersonnelSelection" :showModel="optionsShow" :optionsList="optionsList" @closePersonnelSelect="SelectOptions" @toSearch="toSearch" :showType="false"></personnel-selection>

        </div>
    </div>

</template>
<script>
import TreeMenu from './TreeMenu/index'
import SearchFolder from '../MailPublic/SearchFolder/index.vue'
import { mapGetters, mapMutations } from 'vuex'
import titleMixin from '../../../mixin/title'
import PersonnelSelection from '../MailPublic/PersonnelSelection/index'
import MaliList from './MaliList/index'
import loadingNew from '../MailPublic/LoadingVue'
import hybrid from '../../../libs/hybrid'
import { getIsApp } from '@/libs/utils'

export default {
    name: 'Home',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.Mail.1584681526518') }, // '孚盟邮'
    data() {
        return {
            msg: 'Home',
            myMap: new Map(),
            myMailTree: [],
            radio: {}, // 操作的文件夹ID
            showState: true, // true 显示   false 编辑
            AccountNmber: '', // 当前选中的账号
            beforeNmber: '',
            customerArray: [], // 客户列表
            fromExArry: [], // 发件人列表
            // fromExArryShow: [], // 发件人列表
            // fromExShow: false, // 发件人列表显示
            myAccount: '我的全部邮件',
            optionsList: [], // 下属切换列表
            optionsListShow: [],
            optionsShow: false,
            startCtid: '',
            searchMailIs: false,
            isLoading: true
        }
    },
    created() {
        getIsApp() && hybrid.initCtid(`${this.currentCtid}`)
    },
    // beforeRouteLeave(to, from, next) {
    //     if (this.$refs.PersonnelSelection && this.optionsShow) {
    //         this.SelectOptions('')
    //         next(false)
    //     } else {
    //         next()
    //     }
    // },
    computed: {
        ...mapGetters('Mail', ['currentAddress', 'currentCtid'], ['companie'])
    },
    mounted() {
        // this.quickAdd() // 全局右上角快捷新增
        this.pageInit()
    },
    methods: {
        getIsApp,
        searchClick(isSearching, searchValue) {
            this.searchMailIs = isSearching
            // console.log(searchValue)
            isSearching && this.$router.push({ name: 'mailList', query: { searchValue } })
            // if (blg) {
            //     setTimeout(() => {
            //         this.$refs.MaliList.searchClick(searchValue, 1, blg != true)
            //     }, 300)
            // }
        },
        /* 文件操作按钮 */
        async fileAction() {
            try {
                let res = await this.Global.utils.actionSheet.open(this.fromExArry)
                if (res.buttonIndex == this.fromExArry.length - 1) { // 最后一项(添加账号)
                    this.$router.push({ name: 'mailAccount' })
                } else if (res.buttonIndex < this.fromExArry.length) {
                    this.SelectfromEx(this.fromExArry[res.buttonIndex])
                }
            } catch (error) {
                console.log(error)
            }
        },
        SelectfromEx(item) {
            this.AccountNmber = item.nameS // == '我的全部邮件' ? '' : item.nameSe
            this.set_currentAddress(item.nameS == this.myAccount ? '' : item.nameS)
            this.mailsMailboxesGet()
        },
        ...mapMutations('Mail', {
            set_currentAddress: 'SET_CUURENTADDRESS',
            set_currentCtid: 'SET_CURRENTCTID'
        }),
        optionsOpen() {
            this.optionsShow = false
            this.getsubordinate()
        },
        SelectOptions(selectCtid) {
            this.quickAdd()
            this.optionsShow = false
            this.AccountNmber = this.myAccount
            if (selectCtid != '') {
                this.set_currentCtid(selectCtid)
                getIsApp() && hybrid.initCtid(`${selectCtid}`)
            }
            this.pageInit()
        },

        // 获取页面数据
        async getPersonalData() {
            let data = { 'type': 'total', 'mailAccount': '' }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                data.ctid = this.currentCtid
            } else {
                delete data.ctid
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.GetNumberAccounts
                let res = await this.axios.get(url, { params: data })
                if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isArray(res.data.data)) {
                    let dataR = res.data.data
                    // let listshow = []
                    let list = []
                    for (let i = 0; i < dataR.length; i++) {
                        list.push({
                            name: dataR[i].mailAddress == '' ? '我的全部邮件  ' + dataR[i].unRead : dataR[i].mailAddress + '  ' + dataR[i].unRead,
                            nameS: dataR[i].mailAddress == '' ? '我的全部邮件' : dataR[i].mailAddress,
                            unRead: dataR[i].unRead,
                            Read: dataR[i].Read,
                            total: dataR[i].total,
                            index: i,
                            buttonIndex: i
                        })
                        // listshow.push(dataR[i].mailAddress == '' ? '我的全部邮件  ' + dataR[i].unRead : dataR[i].mailAddress + '  ' + dataR[i].unRead)
                    }
                    // listshow.push('添加邮箱')
                    // this.fromExArryShow = listshow
                    list.push({
                        name: '添加邮箱',
                        buttonIndex: dataR.length
                    })
                    this.fromExArry = list
                    if (list.length > 0 && (this.AccountNmber == '' || this.startCtid != this.currentCtid)) {
                        this.startCtid = this.currentCtid
                        this.AccountNmber = list[0].nameS
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        toSearch(value) {
            if (value.toString() == '') {
                this.optionsList = this.optionsListShow
                return
            }
            let list = []
            this.optionsListShow.forEach(element => {
                if (element.name.indexOf(value) != -1) {
                    list.push(element)
                }
            })
            this.optionsList = list
        },
        // 获取页面数据
        async getsubordinate() {
            try {
                // let data = { type: 'allUserList' }
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.GetPersonnels
                let res = await this.axios.get(url, { params: {} })
                if (res.data.code.toString() == this.Global.config.RES_OK &&
                    this.Global.utils.isArray(res.data.data)) {
                    let data = res.data.data
                    let list = [{ name: '我自己', ctid: this.companie.ctId }]
                    let showList = ['我自己']
                    for (let i = 0; i < data.length; i++) {
                        if (this.companie.ctId != data[i].ctId) {
                            list.push({
                                name: data[i].realName,
                                ctid: data[i].ctId
                            })

                            showList.push(data[i].realName)
                        }
                    }
                    this.optionsList = list
                    this.optionsListShow = list
                    this.optionsShow = true
                    this.setMenu()
                    // console.log(this.optionsShow)
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        CustomerClick(boxId, custName) {
            this.$router.push({ name: 'mailList', params: { target: 'custmerlist', boxId: boxId, boxName: custName } })
        },
        setMenu() {
            let rightMenu = this.Global.utils.rightMenu.setMenu('关闭')
            rightMenu.onClick(() => {
                this.optionsShow = false
                this.quickAdd()
            })
        },
        UndistributedMail() {
            this.$router.push({ name: 'mailList', params: { target: 'undistributed', boxId: '-1' } })
        },
        getTopMailList() {
            this.$router.push({ name: 'mailList', params: { target: 'toplist', boxId: '' } })
        },
        colseShow() {
            this.showState = true
        },
        GetData(Arry, Aname, FID, Account, total, Read, unRead, title) {
            let arrs = []
            for (let i = 0; i < Arry.length; i++) {
                if (Account[Arry[i].boxId] != undefined) {
                    unRead = Account[Arry[i].boxId].unRead == undefined ? 0 : Account[Arry[i].boxId].unRead
                    Read = Account[Arry[i].boxId].Read == undefined ? 0 : Account[Arry[i].boxId].Read
                    total = Account[Arry[i].boxId].total == undefined ? 0 : Account[Arry[i].boxId].total
                } else {
                    unRead = 0
                    Read = 0
                    total = 0
                }
                let Aname2 = Aname + Arry[i].boxName + '/'
                this.myMap.set(Arry[i].boxId.toString(), Aname2)

                let children = Arry[i].child.length > 0 ? this.GetData(Arry[i].child, Aname2, Arry[i].boxId, Account, 0, 0, 0, title) : []
                arrs.push({
                    boxId: Arry[i].boxId,
                    boxName: Arry[i].boxName,
                    children: children,
                    boxType: Arry[i].boxType,
                    boxPath: Arry[i].boxPath,
                    TitleName: Aname2,
                    FID: FID,
                    bgColor: Arry[i].bgColor,
                    total: total,
                    unRead: unRead,
                    Read: Read,
                    position: title,
                    custId: Arry[i].custId,
                    AccountNmber: this.AccountNmber == this.myAccount ? '' : this.AccountNmber // 我的全部邮件
                })
            }
            return arrs
        }, // 获取文件夹列表
        async _getFolderCount(params) {
            let returnData = {}
            const url = this.Global.config.apiBaseURL + this.Global.api.v2.mails_mailscount
            try {
                let res = await this.axios.get(url, { params })

                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    returnData = res.data.data
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }

            return returnData
        },
        async _getMailBoxs(params) {
            let returnData = []
            const url = this.Global.config.apiBaseURL + this.Global.api.v2.mails_mailboxes
            try {
                let res = await this.axios.get(url, { params })

                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    returnData = res.data.data
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
            return returnData
        },
        async mailsMailboxesGet() {
            this.showState = true
            let Account = {}
            let total = 0
            let Read = 0
            let unRead = 0

            this.AccountNmber = this.AccountNmber == '' ? this.currentAddress : this.AccountNmber
            let params01 = { 'type': 'detail', 'mailAccount': this.AccountNmber == this.myAccount ? '' : this.AccountNmber }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                params01.ctid = this.currentCtid
            } else {
                delete params01.ctid
            }
            let params02 = {}
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                params02.ctid = this.currentCtid
            } else {
                delete params02.ctid
            }
            try {
                let results = await Promise.all([this._getFolderCount(params01), this._getMailBoxs(params02)])

                Account = results[0]
                let Arry = results[1]

                let ArrNew = []
                for (let i = 0; i < Arry.length; i++) {
                    if (Account[Arry[i].boxId] != undefined) {
                        unRead = Account[Arry[i].boxId].unRead == undefined ? 0 : Account[Arry[i].boxId].unRead
                        Read = Account[Arry[i].boxId].Read == undefined ? 0 : Account[Arry[i].boxId].Read
                        total = Account[Arry[i].boxId].total == undefined ? 0 : Account[Arry[i].boxId].total
                    } else {
                        unRead = 0
                        Read = 0
                        total = 0
                    }
                    if (this.AccountNmber == '') {
                        this.AccountNmber = this.myAccount
                    }

                    this.myMap.set(Arry[i].boxId.toString(), Arry[i].boxName)
                    let childAryy = Arry[i].child.length > 0 ? this.GetData(Arry[i].child, Arry[i].boxName + '/', Arry[i].boxId, Account,
                        0, 0, 0, i) : []
                    ArrNew.push({
                        boxId: Arry[i].boxId,
                        boxName: Arry[i].boxName,
                        children: childAryy,
                        boxType: Arry[i].boxType,
                        boxPath: Arry[i].boxPath,
                        TitleName: Arry[i].boxName + '/',
                        FID: -2,
                        bgColor: Arry[i].bgColor,
                        total: total,
                        unRead: unRead,
                        Read: Read,
                        position: i,
                        custId: Arry[i].custId,
                        AccountNmber: this.AccountNmber == this.myAccount ? '' : this.AccountNmber // 我的全部邮件
                    })
                }
                this.isLoading = false
                this.myMailTree = ArrNew
            } catch (error) {
                console.log(error)
            }

            this.active = -1
            this.radio = {}
        },
        // 上移
        Move() {
            if (Object.keys(this.radio).length == 0) {
                // this.$dialog.alert({ title: '提示', message: '请选择文件夹' })
                this.$toast.fail('请选择文件夹')
                return
            }

            let obj = this.radio
            if (obj.RowIndex == 0) {
                // _this.$dialog.alert({ title: '提示', message: '已经是顶层了' })
                this.$toast.fail('已经是顶层了')
            } else {
                let targetBoxId = obj.dataObj[obj.RowIndex - 1].boxId
                let data = { action: 'move', boxId: obj.boxId, targetBoxId: targetBoxId }
                if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                    data.ctid = this.currentCtid
                } else {
                    delete data.ctid
                }
                this.axios.put(this.Global.config.apiBaseURL + this.Global.api.v2.mails_mailbox, data)
                    .then((res) => {
                        if (res.data.code.toString() == this.Global.config.RES_OK) {
                            this.mailsMailboxesGet()
                            this.$toast.success('上移成功')
                        } else {
                            this.$toast.fail(res.data.msg)
                        }
                    })
                    .catch((error) => {
                        this.$toast.fail(error)
                    })
            }
        },
        // 下移
        Down() {
            if (Object.keys(this.radio).length == 0) {
                // this.$dialog.alert({ title: '提示', message: '请选择文件夹' })
                this.$toast.fail('请选择文件夹')
                return
            }

            let obj = this.radio
            if (obj.dataObj.length - 1 == obj.RowIndex) {
                this.$toast.fail('已经是底层了')
            } else {
                let targetBoxId = obj.dataObj[obj.RowIndex + 1].boxId
                let data = { action: 'move', boxId: obj.boxId, targetBoxId: targetBoxId }
                if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                    data.ctid = this.currentCtid
                } else {
                    delete data.ctid
                }
                this.axios.put(this.Global.config.apiBaseURL + this.Global.api.v2.mails_mailbox, data)
                    .then((res) => {
                        if (res.data.code.toString() == this.Global.config.RES_OK) {
                            this.mailsMailboxesGet()
                            this.$toast.success('下移成功')
                        } else {
                            this.$toast.fail(res.data.msg)
                        }
                    }).catch((err) => {
                        console.log(err)
                    })
            }
        }, // 删除文件夹
        DleteFolder() {
            if (Object.keys(this.radio).length == 0) {
                // this.$dialog.alert({ title: '提示', message: '请选择文件夹' })
                this.$toast.fail('请选择文件夹')
                return
            }
            let _this = this
            let obj = _this.radio
            let alterStr = '您确定删除此文件夹吗？'
            if (obj.treeData.children.length > 0) {
                alterStr = '此项有子文件夹，确定要删除吗？'
            }
            // 提示
            _this.$dialog.confirm({
                title: '提示',
                message: alterStr
            }).then(() => {
                let boxpathStr = obj.treeData.boxPath.split('/')
                let bay = false
                for (let i = 0; i < boxpathStr.length; i++) {
                    if (boxpathStr[i] == 4) {
                        bay = true
                        break
                    }
                }
                if (bay) { // 真正删除
                    let data = { boxId: obj.treeData.boxId }
                    if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                        data.ctid = this.currentCtid
                    } else {
                        data.ctid = this.companie.ctId
                    }
                    _this.axios.delete(_this.Global.config.apiBaseURL + _this.Global.api.v2.mails_mailbox, { data }).then(function (res) {
                        if (res.data.code.toString() == _this.Global.config.RES_OK) {
                            _this.mailsMailboxesGet()
                            _this.$toast.success('删除成功')
                        } else {
                            _this.$toast.fail(res.data.msg)
                        }
                    },
                        function (res) {
                            _this.$toast.success(_this.$t(_this.Global.config.errorTitle))
                        })
                } else { // 移入回收站
                    let data = { action: 'remove', boxId: obj.treeData.boxId, targetBoxId: 4 }
                    if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                        data.ctid = this.currentCtid
                    } else {
                        data.ctid = this.companie.ctId
                    }
                    _this.axios.put(_this.Global.config.apiBaseURL + _this.Global.api.v2.mails_mailbox, data).then(function (res) {
                        if (res.data.code.toString() == _this.Global.config.RES_OK) {
                            _this.mailsMailboxesGet()
                            _this.$toast.success('删除成功')
                        } else {
                            _this.$toast.fail(res.data.msg)
                        }
                    },
                        function (res) {
                            _this.$toast.success(_this.$t(_this.Global.config.errorTitle))
                        })
                }
            }).catch(() => {
                // on cancel
            })
        },
        // 新增文件夹
        actionFolder() {
            if (Object.keys(this.radio).length == 0) {
                // this.$dialog.alert({ title: '提示', message: '请选择文件夹' })
                this.$toast.fail('请选择文件夹')
                return
            }
            this.$router.push({ name: 'folderSelection', params: { boxId: this.radio.boxId, boxPath: this.radio.boxPath } })
        },
        // 修改文件夹名称
        editFolder() {
            if (Object.keys(this.radio).length == 0) {
                // this.$dialog.alert({ title: '提示', message: '请选择文件夹' })
                this.$toast.fail('请选择文件夹')
                return
            } else if (this.radio.boxType == 0) {
                // this.$dialog.alert({ title: '提示', message: '系统文件夹不允许修改' })
                this.$toast.fail('系统文件夹不允许修改')
                return
            }
            this.$router.push({ name: 'folderAdd', params: { boxId: this.radio.boxId, boxName: this.radio.boxName } })
        },
        async getcustomer() {
            let params = {
                moduleCode: 'BF001',
                searchType: 'focusedList'
            }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                params.ownerCtId = this.currentCtid // 需要特别注意
                params.from = 0
                params.size = 10000
                params.fromModule = 'EM001' // 赋予邮件模块权限
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.generalOperateGet
                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isObject(res.data.data)) {
                    let dataResult = res.data.data.list
                    let Arrylist = []
                    if (dataResult.length > 100) {
                        for (let i = dataResult.length - 1; i > dataResult.length - 100; i--) {
                            Arrylist.push({
                                custId: dataResult[i].custId,
                                custName: dataResult[i].custName
                            })
                        }
                    } else {
                        for (let i = 0; i < dataResult.length; i++) {
                            Arrylist.push({
                                custId: dataResult[i].custId,
                                custName: dataResult[i].custName
                            })
                        }
                    }
                    this.customerArray = Arrylist
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        pageInit() {
            if (this.$route.path == '/mail/home') {
                this.mailsMailboxesGet()
                this.getcustomer()
                this.getPersonalData()
                this.quickAdd() // 全局右上角快捷新增
                this.searchMailIs = false
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.optionsShow) {
            this.optionsShow = false
            next(false)
            return
        }
        const flag = this.Global.utils.chosen.close()
        const flag2 = this.Global.utils.actionSheet.close()
        const flag3 = this.Global.utils.prompt.close()
        if (flag || flag2 || flag3) {
            next(false)
        } else {
            next()
        }
    },
    watch: {
        $route(to, from) {
            this.pageInit()
            if (from.path == 'mail/mailList') {
                this.mailsMailboxesGet()
            }
        }
    },
    components: {
        'tree-menu': TreeMenu,
        'personnel-selection': PersonnelSelection,
        'search-folder': SearchFolder,
        'mali-list': MaliList,
        'loading-new': loadingNew
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
.appNavView {
    bottom: 0;
}

.zh-cn .Home .navView .treeWrap .treeItem .title i.iconThis.icon-mail-not {
    position: static;
}
</style>
