<template>
    <li>
        <div @click="treeItemClick(treeData.boxId,treeData.boxName)">
            <i v-if="isFolder" @click.stop="toggle" class="iconfont " :class="[open ? 'icon-arrow-down': 'icon-arrow-right']"></i>
            <span class="label">
                <span class="labelColor">{{treeData.boxName}}</span>
            </span>
            <span v-if="showState&&treeData.boxId==0" class="unReadClass">
                <span style="color:#d0021b">
                    {{treeData.unRead}}<span style="color: #909399;">/{{treeData.total}}</span>
                </span>
                <span style="position: relative; top: 1px;"> <i class="iconfont  icon-page-right"></i></span>
            </span>
            <span v-if="showState&&treeData.boxId!=0" class="unReadClass">
                <span v-if="treeData.unRead>0" style="color:#d0021b">
                    {{treeData.unRead}}
                </span>
                <span style="position: relative; top: 1px;"> <i class="iconfont  icon-page-right"></i></span>

            </span>

            <span v-if="!showState" class="unReadClass2" @click.stop="stopAction($event)">
                <!-- <input type="radio" > -->

                <van-radio :name="{boxId:treeData.boxId,boxPath:treeData.boxPath,boxName:treeData.boxName,boxType:treeData.boxType,RowIndex:RowIndex,dataObj:myMailTree,treeData:treeData}"></van-radio>

            </span>

        </div>
        <ul class="T_down" v-show="open" v-if="isFolder">
            <tree-menu v-for="(item,index) in treeData.children" :key="index" :treeData="item" :RowIndex="index" :myMailTree="treeData.children" :showState="showState"></tree-menu>
        </ul>
    </li>
</template>
<script>
export default {
    name: 'treeMenu',
    props: ['myMailTree', 'treeData', 'RowIndex', 'showState'],
    data() {
        return {
            open: false
            // , radio: ''
        }
    },
    computed: {
        isFolder() {
            return this.treeData.children && this.treeData.children.length
        }

    },
    created() {
    },
    mounted() {
    },
    methods: {
        // 停止冒泡
        stopAction(e) {
            e.stopPropagation()
        },
        treeItemClick(boxId, boxName) {
            this.$router.push({ name: 'mailList', params: { target: 'folder', boxId: boxId, boxName: boxName } })
            // this.$router.push({ name: 'DialogInDistribute', params: { mIds: [205756] } })
        },
        // 展开子菜单
        toggle: function () {
            if (this.isFolder) {
                this.open = !this.open
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
